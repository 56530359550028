<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="10px"
  style="margin-top: 20px"
>
  <div>
    <h4 class="pagination-count pagination-count-custom">
      {{ "pagination_text_1" | translate }} {{ items.length }} {{
      "pagination_text_2" | translate }} {{ totalItem }} {{ "pagination_text_3"
      | translate }}
    </h4>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
    style="margin-top: 20px"
  >
    <img
      src="/assets/icons/Left.svg"
      alt="Previous page"
      style="cursor: pointer"
      (click)="onPreviousPage()"
    />
    <div class="pagination-div" fxLayout="row" fxLayoutGap="10px">
      <h4
        *ngFor="let pageItem of pageCount()"
        class="paginataion-btn"
        [ngClass]="{
                'pagination-btn-selected': pageItem == page
              }"
        (click)="onChangePage(pageItem)"
      >
        {{ pageItem }}
      </h4>
    </div>
    <img
      src="/assets/icons/Right.svg"
      alt="Next page"
      style="cursor: pointer"
      (click)="onNextPage()"
    />
  </div>
</div>
