import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { GeneralService } from "app/auth/service";
import { Subject } from "rxjs";

@Component({
  selector: "pagination",
  templateUrl: "./pagination.html",
})
export class PaginationComponent implements OnInit, OnDestroy {
  public lang;
  private unsubscribe: Subject<any>;

  @Input() pageSize: number;
  @Input() page: number;
  @Input() totalItem: number;
  @Input() items: any[];
  @Output() onPageChange = new EventEmitter<number>();

  constructor(private generalService: GeneralService) {
    this.unsubscribe = new Subject();
  }

  ngOnInit(): void {
    this.generalService.currentLanguage.subscribe((lang) => {
      this.lang = lang;
    });
    this.generalService.setLanguage();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  pageCount() {
    const maxPage = Math.ceil(this.totalItem / this.pageSize);

    return Array.from(Array(maxPage).keys()).map((item) => item + 1);
  }

  onChangePage(page: number) {
    this.onPageChange.emit(page);
  }

  onPreviousPage() {
    if (this.page === 1) {
      return;
    }
    this.onPageChange.emit(this.page - 1);
  }

  onNextPage() {
    const maxPage = Math.ceil(this.totalItem / this.pageSize);

    if (this.page === maxPage) {
      return;
    }

    this.onPageChange.emit(this.page + 1);
  }
}
